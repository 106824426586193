<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>
          <div
            v-show="showStationsSelect"
            class="mt-5 col-md-2"
          >
            <v-col
              class="d-flex"
            >
              <v-select
                v-model="station"
                mt-50
                :items="stations"
                item-text="name"
                item-value="id"
                label="Station"
                :loading="isStationsLoading"
                dense
              ></v-select>
            </v-col>
          </div>

          <theme-switcher></theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div
        v-if="showFooter"
        class="boxed-container w-full"
      >
        <div class="mx-6 d-flex justify-space-between">
          <span class="d-sm-inline d-none">
            <h5>
              &copy; {{ year }}. Powered by <a
                href="https://www.dashernet.com"
                target="_blank"
                class="footer-link"
              >Dashernet</a>
            </h5>
          </span>
        </div>
      </div>
    </v-footer>
    <auto-logout />
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiHeart } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AutoLogout from '@/components/partials/AutoLogout.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    AutoLogout,
  },
  data: () => ({
    stations: [],
    station: null,
    isStationsLoading: true,
    showStationsSelect: true,
    routesToHideStationsSelect: [
      'dashboard',
      'stations',
      'routers',
      'settings',
      'internet-plan-analytics',
      'payment-analytics',
      'sms-analytics',
    ],
  }),
  computed: {
    ...mapGetters(['selectedStation', 'shouldRefreshStations']),
    currentRouteName() {
      return this.$route.name
    },
    showFooter() {
      return process.env.VUE_APP_HIDE_FOOTER !== 'true'
    },
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      year: new Date().getFullYear(),

      // Icons
      icons: {
        mdiHeart,
      },
    }
  },
  watch: {
    shouldRefreshStations(refresh) {
      if (refresh) {
        this.getStations()
      }
    },
    currentRouteName(name) {
      if (this.shouldHideStationSelect(name)) {
        this.showStationsSelect = false
      } else {
        this.showStationsSelect = true
      }
    },
    stations() {
      if (this.stations.length > 0) {
        if (this.selectedStation === null || this.selectedStation === undefined || this.selectedStation === '') {
          this.station = this.stations[0].id
        } else {
          this.station = this.selectedStation
        }
      }
    },
    station() {
      this.setSelectedStation(this.station)
    },
  },
  mounted() {
    this.getStations()
    if (this.shouldHideStationSelect(this.currentRouteName)) {
      this.showStationsSelect = false
    } else {
      this.showStationsSelect = true
    }
    this.shouldHideStationSelect(this.currentRouteName)
  },
  methods: {
    ...mapActions(['setSelectedStation', 'refreshStations']),
    getStations() {
      this.isStationsLoading = true
      axios
        .get('stations?perPage=all')
        .then(response => {
          this.stations = response.data

          // add 'All Stations' option at the beginning
          this.stations.unshift({
            id: 'all',
            name: 'All Stations',
            location: 'all',
            paybill_number: 'all',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            customers_count: 0,
          })
          this.isStationsLoading = false
          this.refreshStations(false)
        })
        .catch(error => {
          this.isStationsLoading = false
          this.$toast.error(error.response.data.message)
        })
    },
    shouldHideStationSelect(routeName) {
      return this.routesToHideStationsSelect.includes(routeName)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.footer-link {
  text-decoration: none;
}
</style>
